import React from 'react';
import Box from '@material-ui/core/Box';

function SmallImageHero() {
  return (
    <React.Fragment>
      <Box bgcolor="primary.main" py={[12, 12, 16]} position="relative"></Box>
    </React.Fragment>
  );
}

export default SmallImageHero;
